@use '@/styles/utils/mixins.scss' as *;
.headerWrapper {
  background-color: $kiaPolarWhite;
  padding-top: 80px;
  padding-bottom: 56px;
  @include mob-tab() {
    padding-top: 28px;
    padding-bottom: 20px;
  }
  &.stickyHeader {
    padding-bottom: 20px;
    .headingElement {
      opacity: 0;
      height: 0;
      display: none;
    }
  }
  .headingElement {
    position: relative;
    transition: all 0s ease-in-out;
    height: auto;
    opacity: 1;
    @include mob-tab() {
      justify-self: center;
      align-self: center;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
        @include mob() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 20px;
        line-height: 1;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
  }

  .headerContainer {
    display: flex;
    gap: 16px;

    justify-content: space-between;
    align-items: flex-start;
    @include mob-tab() {
      flex-direction: column;
      gap: 4px;
    }
  }

  .backBtn {
    display: flex;
    background: transparent !important;
    width: max-content;
    flex: 0 0 auto;
    padding: 0;
    height: auto !important;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    border-radius: 0 !important;
    color: $kiaMidnightBlack;
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
    margin-bottom: 10px;

    svg {
      padding-bottom: 2px;

      &:focus {
        color: $kiaMidnightBlack;
        outline: 0;
      }
    }
    .backBtnText {
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      padding-bottom: 2px;
      position: relative;
      &::before {
        content: '';
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        height: 1px;
        width: 0;
        background-color: $kiaMidnightBlack;
        transition: width 0.3s;
      }

      &:focus {
        color: $kiaMidnightBlack;
        outline: 0;
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: $kiaMidnightBlack;
      .backBtnText {
        color: $kiaMidnightBlack;
        &::before {
          width: 100%;
          transition: width 0.3s;
        }
      }
    }
    &.active {
      color: $kiaMidnightBlack;
      .backBtnText {
        color: $kiaMidnightBlack;
        &::before {
          width: 100%;
          transition: width 0.3s;
        }
      }
    }
  }
}
