@use '@/styles/utils/mixins.scss' as *;

.promotionsCard {
  min-height: 476px;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include tab() {
    min-height: 548px;
  }
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
        @include mob() {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  &Image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: $kiaMidnightBlack;
    z-index: -1;
    img {
      width: 100%;
      height: auto;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      background: linear-gradient(
        90deg,
        rgba(5, 20, 31, 1) 0%,
        rgba(5, 20, 31, 0) 80%
      );
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
      background: rgba(5, 20, 31, 1);
      background: linear-gradient(
        180deg,
        rgba(5, 20, 31, 0) 0%,
        rgba(5, 20, 31, 0) 55%,
        rgba(5, 20, 31, 1) 71%
      );
      @include mob() {
        background: linear-gradient(
          180deg,
          rgba(5, 20, 31, 0) 0%,
          rgba(5, 20, 31, 0) 50%,
          rgba(5, 20, 31, 1) 66%
        );
      }
    }
  }
  &Header {
    .promotionTag {
      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 14.4px;
        color: $kiaPolarWhite;
        margin-top: 2px;
      }
    }
    .darkTag {
      background: $description;
    }
    .redTag {
      background: $kiaLiveRed;
    }
  }
  &Body {
    z-index: 2;
    position: relative;
    .carModelName {
      font-size: 36px;
      line-height: 43.2px;
      font-weight: 600;
      color: $kiaPolarWhite;
      display: inline-block;
      @include mob() {
        font-size: 24px;
        line-height: 28.8px;
      }
    }
  }
  &Footer {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(5, 20, 31, 1) 100%
    );
    z-index: 2;
    position: relative;
    .carModelDetail {
      .carInfo {
        .description {
          color: $kiaPolarWhite;
          font-size: 16px;
          line-height: 1.4;
          font-weight: 400;
          display: inline-block;
          @include mob() {
            font-size: 14px;
          }
        }
      }
      .carPriceInfo {
        .carPriceDesc {
          color: $kiaPolarWhite;
          font-size: 12px;
          line-height: 1.2;
        }
        .carPrice {
          font-weight: 600;
          font-size: 20px;
          line-height: 1.2;
          color: $kiaPolarWhite;
          white-space: nowrap;
          display: flex;
          gap: 4px;

          @media screen and (min-width: 767px) and (max-width: 991.98px) {
            font-size: 16px;
          }
          @media screen and (min-width: 992px) and (max-width: 1180px) {
            font-size: 18px;
          }
        }
      }
    }
    @media screen and (min-width: 768px) {
      .onHoverCTA {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 1;
        height: 100%;
        transition: all 0.4s linear 0s;
        transform: translateY(100%);
        visibility: hidden;
      }
      .carModelDetail {
        transition: all 0s linear 0.4s;
      }
    }
    .btnCTA {
      padding: 13px 15px;
      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  &:hover {
    cursor: pointer;
    @media screen and (min-width: 768px) {
      .carModelDetail {
        visibility: hidden;
      }
      .onHoverCTA {
        top: 0;
        transform: translateY(0);
        visibility: visible;
      }
    }
  }
}
