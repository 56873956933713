@use '@/styles/utils/mixins.scss' as *;

.promotionsWrapper {
  .headingElement {
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleSm {
        font-size: 20px;
        line-height: 24px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  .latestPromotionsHeading {
    font-family: 'KiaSignature';
    font-weight: 600;
    font-style: normal;
    font-size: 48px;
    line-height: 60px;
    @include tab() {
      font-size: 36px;
      line-height: 43.2px;
    }
  }
  background: var(--Primary-Colours-Kia-Midnight-Black, $kiaMidnightBlack);
  display: flex;
  flex-direction: column;
  .featureSlider {
    max-width: 1280px;
    width: 100%;
    @media screen and (max-width: 576px) {
      max-width: 100%;
    }
    .swiper-wrapper {
      .swiper-slide {
        width: fit-content;
        @media screen and (min-width: 768px) {
          margin-right: 40px;
        }
        @media screen and (max-width: 480px) {
          max-width: 100%;
        }
      }
    }
  }
  .featureSwiperControls {
    display: flex;
    align-items: center;
    justify-content: end;
    max-width: 1280px;
    width: 100%;
    margin: 40px auto 0 auto;
    @media screen and (max-width: 576px) {
      margin: 40px 0 0 auto;
    }

    &.showNavigation {
      padding-right: 0;
      @media screen and (min-width: 1024px) {
        display: flex;
      }
      @media screen and (max-width: 576px) {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
  .promotionSwiperNavigation {
    display: flex;
    align-items: center;
  }
  .promotionSwiperButtonNext,
  .promotionSwiperButtonPrev {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    svg {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      width: 48px;
      height: 48px;
    }
  }
  .promotionSwiperPagination {
    background-color: $keyline;
    border-radius: 15px;
    color: $kiaMidnightBlack;
    padding: 5px 10px;
    position: static;
    bottom: 0;
    right: 0;
    width: auto;
    left: auto;
    margin-right: 2px;
    line-height: 1;
    min-width: 48px;
    text-align: center;
    span {
      font-size: 12px;
      line-height: 14.4px;
    }
  }

  .featureSectionCard {
    min-height: 565px;
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 60%;
      background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      @media screen and (max-width: 576px) {
        background: linear-gradient(
          180deg,
          rgba(5, 20, 31, 0) 0%,
          $kiaMidnightBlack 100%
        );
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1160px) {
      min-height: 490px;
    }
    @media screen and (max-width: 767px) {
      min-width: 248px;
    }
    @media screen and (max-width: 576px) {
      margin-left: 0px;
      margin-right: 0px;
      min-height: 460px;
    }
    .featureSectionCardImage {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      object-fit: fill;
      z-index: -1;
    }
  }

  .cardContent {
    padding: 24px 23px;
    margin-top: auto;
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 30%
    );
    .label {
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      color: $kiaPolarWhite;
      margin-bottom: 8px;
    }
    .modalYear {
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      color: $kiaPolarWhite;
      margin-bottom: 24px;
    }
  }
  .headingElement {
    max-width: 100%;
    padding: 0 24px 0 64px;
    margin-bottom: 80px;
    color: $kiaPolarWhite;
    .btn {
      display: block;
      padding: 15px 30px;
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    @media screen and (min-width: 576px) {
      display: none;
    }
    @media screen and (max-width: 374px) {
      padding: 0 24px 0 24px;
    }
  }
}

.dealershipHeader {
  .headingElement {
    display: block;
  }
  .outlineWhite {
    border: 1px solid $keyline;
    margin: 12px 12px 12px 0;
    &:hover {
      border-color: $kiaPolarWhite;
    }
    &.active {
      border-color: $kiaPolarWhite;
    }
  }
}
