@use '@/styles/utils/mixins.scss' as *;

.servicePartsCard {
  position: relative;
  z-index: 1;
  min-height: 476px;
  height: 100%;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(5, 20, 31, 0) 40%,
      $kiaMidnightBlack 100%
    );
  }
  &Header {
    .promotionTag {
      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 14.4px;
        color: $kiaMidnightBlack;
        margin-top: 2px;
      }
    }
    .whiteTag {
      background: $kiaPolarWhite;
    }
    .darkTag {
      background: $description;
    }
    .redTag {
      background: $kiaLiveRed;
    }
  }
  &Image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 476px;
    height: auto;
    background-color: $kiaMidnightBlack;
    z-index: -1;
    img {
      width: 100%;
      height: auto;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
      background: rgba(5, 20, 31, 1);
      background: linear-gradient(
        180deg,
        rgba(5, 20, 31, 0) 0%,
        rgba(5, 20, 31, 0) 55%,
        rgba(5, 20, 31, 1) 71%
      );
      @include mob() {
        background: linear-gradient(
          180deg,
          rgba(5, 20, 31, 0) 0%,
          rgba(5, 20, 31, 0) 50%,
          rgba(5, 20, 31, 1) 66%
        );
      }
    }
  }
  &Footer {
    z-index: 1;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleMd {
        font-size: 36px;
        line-height: 43.2px;
        @media screen and (max-width: 1140px) {
          font-size: 24px;
          line-height: 28.8px;
        }
        @include tab() {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
    .CTAwrapper {
      position: relative;
      min-height: 55px;
    }
    @media screen and (min-width: 768px) {
      .CTAwrapper {
        .onHoverCTA {
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          z-index: 1;
          height: 100%;
          transition: all 0.4s linear 0s;
          transform: translateY(100%);
          visibility: hidden;
        }
        .titleText {
          transition: all 0s linear 0.4s;
        }
      }
    }
  }
  &:hover {
    cursor: pointer;
    @media screen and (min-width: 768px) {
      .CTAwrapper {
        .titleText {
          visibility: hidden;
        }
        .onHoverCTA {
          top: 0;
          transform: translateY(0);
          visibility: visible;
        }
      }
    }
  }
}
