@use '@/styles/utils/mixins.scss' as *;

.warrantySectionWrapper {
  background-color: #f8f8f8;
  border-top: 1px solid $keyline;
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 40.2px;
        }
        @include mob() {
          font-size: 24px;
          line-height: 36px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleSm {
        font-size: 20px;
        line-height: 24px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
    .titleText {
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
    }
  }

  .themeLink {
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 600;
    color: $kiaMidnightBlack;
    text-transform: capitalize;
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      width: 0;
      background-color: $kiaMidnightBlack;
      transition: width 0.3s;
    }
    &:hover::before,
    &:focus::before,
    &:active::before {
      width: 100%;
      transition: width 0.3s;
    }
    .menuIcon {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: auto;
      right: -15px;
      z-index: 1;
      margin-top: -5px;
      transition: all ease-in-out 0.3s;
    }
    &:hover,
    &:active {
      color: $kiaMidnightBlack;
      text-decoration: none !important;
    }
  }
  .warrantyImg {
    margin: 0 auto 24px;
  }
  .warrantyDetail {
    .themeLink {
      .menuIcon {
        margin-top: -9px;
        right: -25px;
      }
    }
  }
}
