@use '@/styles/utils/mixins.scss' as *;

.CTASectionWrapper {
  border-top: 1px solid $keyline;
  .themeLink {
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 600;
    color: $kiaMidnightBlack;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      width: 0;
      background-color: $kiaMidnightBlack;
      transition: width 0.3s;
    }
    &:hover::before,
    &:focus::before,
    &:active::before {
      width: 100%;
      transition: width 0.3s;
    }
    .menuIcon {
      display: inline-block;
      position: relative;
      right: -5px;
      z-index: 1;
      transition: all ease-in-out 0.3s;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      text-decoration: none !important;
    }
    &:hover,
    &:active {
      color: $kiaMidnightBlack;
      text-decoration: none !important;
    }
  }

  .toolsCard {
    width: 100%;
    max-width: 360px;

    .toolIcon {
      width: 90px;
      height: 90px;
      margin: 0 auto 16px;
      @media screen and (max-width: $minTablet) {
        width: 60px;
        height: 60px;
      }
    }
    .themeLink {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 16px;
      @media screen and (max-width: 991.98px) {
        font-size: 16px;
        line-height: 19.2px;
      }
      @media screen and (max-width: $minTablet) {
        font-weight: 400;
        .menuIcon {
          width: 20px;
          height: 20px;
          margin-top: -3px;
        }
      }
    }
    .toolDescription {
      font-size: 14px;
      line-height: 19.6px;
    }
  }
  .CTASection {
    .toolsCard {
      min-height: 200px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid $keyline;
      border-bottom: 1px solid $keyline;
      &:nth-of-type(1),
      &:nth-of-type(4) {
        background-color: $background;
      }
      &:nth-of-type(2) {
        border-right: 0px;
      }
      &:nth-of-type(3),
      &:nth-of-type(4) {
        border-bottom: 0px;
      }
      @media screen and (max-width: $minTablet) {
        min-height: 180px;
        padding: 16px;
      }
    }
  }
}
